<template>
  <v-container>
    <div class="folha">
      <table style="min-width:21cm;">
        <tbody>
          <tr>
            <td colspan="3">
              <div class="space"></div>
              <table class="tbl">
                <tr>
                  <td style="width:1cm;position:relative;">
                    <img
                      src="../../assets/sant.jpg"
                      style="height:35px;margin: auto 5px;"
                    />
                    <div class="vertical"></div>
                  </td>
                  <td style="min-width:2cm;position:relative;">
                    <h1 style="font-size:160%;margin:0px 10px;margin-top:20px;">
                      033-7
                    </h1>
                    <div class="vertical"></div>
                  </td>
                  <td style="min-width:12cm;text-align:right;">
                    <h1 style="font-size:160%;margin:0px 10px;margin-top:20px;">
                      RECIBO DO SACADO
                    </h1>
                  </td>
                </tr>
              </table>

              <table class="tbl">
                <tr>
                  <td style="border:1px solid #000;min-width:3.25cm;">
                    <code>Data Documento</code><br />
                    <center>
                      <span class="mnSt">{{ datadoc }}</span>
                    </center>
                  </td>
                  <td style="border:1px solid #000;min-width:7cm;">
                    <code>Nosso Número</code><br />
                    <center>
                      <span class="mnSt">{{ nossonumero }}</span>
                    </center>
                  </td>
                  <td style="border:1px solid #000;min-width:3.25cm;">
                    <code>Vencimento</code><br /><span
                      class="mnSt"
                      style="float:right"
                      >{{ vencimento }}
                    </span>
                  </td>
                  <td style="border:1px solid #000;min-width:3.5cm;">
                    <code>Valor do Documento</code><br /><span
                      class="mnSt"
                      style="float:right"
                      >{{ valor }}</span
                    >
                  </td>
                </tr>
              </table>

              <table class="tbl">
                <tr style="height:32px">
                  <td style="border:1px solid #000;min-width:11.5cm;">
                    <code>Sacado</code><br /><span class="mnSt">{{
                      nomedevedor
                    }}</span>
                  </td>
                  <td style="min-width:5.5cm;text-align:center;">
                    <code>Autenticação Mecânica</code>
                  </td>
                </tr>
              </table>

              <hr />
              <br />
              <table class="tbl">
                <tr>
                  <td style="width:1cm;position:relative;">
                    <img
                      src="../../assets/sant.jpg"
                      style="height:35px;margin: auto 5px;"
                    />
                    <div class="vertical"></div>
                  </td>
                  <td style="min-width:2cm;position:relative;">
                    <h1 style="font-size:160%;margin:0px 10px;margin-top:20px;">
                      033-7
                    </h1>
                    <div class="vertical"></div>
                  </td>
                  <td style="min-width:12cm;text-align:right;">
                    <h1 style="font-size:130%;margin:0px 10px;margin-top:20px;">
                      {{ barcodeVal }}
                    </h1>
                  </td>
                </tr>
              </table>

              <table class="tbl">
                <tr>
                  <td
                    style="border:1px solid #000;min-width:11.5cm;border-bottom:0"
                  >
                    <code>Local de Pagamento</code><br /><span class="mnSt"
                      >PAGÁVEL PREFERENCIALMENTE NAS LOJAS NALIN OU BANCO
                      SANTANDER</span
                    >
                  </td>
                  <td
                    style="border:1px solid #000;min-width:5.5cm;text-align:center;border-bottom:0"
                  >
                    <code
                      ><b style="float:right;font-weight:600"
                        >Vencimento</b
                      ></code
                    ><br /><span class="mnSt" style="float:right">{{
                      vencimento
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td
                    style="border:1px solid #000;min-width:11.5cm;border-bottom:0"
                  >
                    <code>Cedente</code><br /><span class="mnStMn"
                      >ACREDIT ADMINISTRATORA DE CARTOES DE CREDITO LTDA (CNPJ:
                      16.783.940/0001-34) <br />R. DR SIQUEIRA 67 - 25900000,
                      CENTRO, MAGE - RJ</span
                    >
                  </td>
                  <td
                    style="border:1px solid #000;min-width:5.5cm;text-align:center;border-bottom:0"
                  >
                    <code style="float:right"
                      >Agência / Código do Beneficiário</code
                    ><br /><span class="mnSt" style="float:right"
                      >4675/9752170</span
                    >
                  </td>
                </tr>
              </table>

              <table class="tbl">
                <tr>
                  <td style="border:1px solid #000;min-width:1.5cm;">
                    <code>Data do Documento</code><br /><span class="mnSt">{{
                      datadoc
                    }}</span>
                  </td>
                  <td style="border:1px solid #000;min-width:5.5cm;">
                    <code>Número do Documento</code><br /><span
                      class="mnSt"
                      style="float:right"
                      >{{ numdoc }}</span
                    >
                  </td>
                  <td style="border:1px solid #000;min-width:2cm;">
                    <code>Espécie Doc</code><br />
                    <center><span class="mnSt">31</span></center>
                  </td>
                  <td style="border:1px solid #000;min-width:1.5cm;">
                    <code>Aceite</code><br /><span class="mnSt"> </span>
                  </td>
                  <td style="border:1px solid #000;min-width:2cm;">
                    <code>Data do Processamento</code><br /><span
                      class="mnSt"
                      >{{ datadoc }}</span
                    >
                  </td>
                  <td style="border:1px solid #000;min-width:3.73cm;">
                    <code>Carteira/Nosso Número</code><br /><span class="mnSt"
                      >101/{{ nossonumero }}</span
                    >
                  </td>
                </tr>
              </table>

              <table class="tbl">
                <tr>
                  <td
                    style="border:1px solid #000;border-top:0;min-width:1.5cm;"
                  >
                    <code>Uso Banco</code><br /><span class="mnSt"> </span>
                  </td>
                  <td
                    style="border:1px solid #000;border-top:0;min-width:3.5cm;"
                  >
                    <code>Carteira</code><br /><span class="mnSt">SR</span>
                  </td>
                  <td style="border:1px solid #000;border-top:0;min-width:2cm;">
                    <code>Espécie</code><br /><span class="mnSt">R$</span>
                  </td>
                  <td
                    style="border:1px solid #000;border-top:0;min-width:3.5cm;"
                  >
                    <code>Quantidade</code><br /><span class="mnSt"> </span>
                  </td>
                  <td style="border:1px solid #000;border-top:0;min-width:4cm;">
                    <code>Valor</code><br /><span class="mnSt"> </span>
                  </td>
                  <td
                    style="border:1px solid #000;border-top:0;min-width:3.75cm;"
                  >
                    <code
                      ><b style="font-weight:600">Valor do Documento:</b></code
                    ><br /><span class="mnSt">{{ valor }}</span>
                  </td>
                </tr>
              </table>

              <table class="tbl" style="border:1px solid #000;border-top:0;">
                <tr style="border-bottom:1px solid #000;">
                  <td
                    rowspan="5"
                    style="border-right:1px solid #000;min-width:15.98cm;"
                  >
                    <code
                      >Instrucões (Todas as informações deste BOLETO são de
                      exclusiva responsabilidade do beneficiário)</code
                    ><br />
                    <code>{{ instru1 }} {{ acordo }}</code
                    ><br />
                  </td>
                  <td style="min-width:1.6cm;">
                    <code>(-) Desconto/Abatimento </code><br />
                    <span class="mnSt"> </span>
                  </td>
                </tr>
                <tr style="border-bottom:1px solid #000;">
                  <td>
                    <code>(-) Outras Deduções </code><br />
                    <span class="mnSt"> </span>
                  </td>
                </tr>
                <tr style="border-bottom:1px solid #000;">
                  <td>
                    <code>(+) Mora/Multa </code><br />
                    <span class="mnSt"> </span>
                  </td>
                </tr>
                <tr style="border-bottom:1px solid #000;">
                  <td>
                    <code>(+) Outros Acréscimos </code><br />
                    <span class="mnSt"> </span>
                  </td>
                </tr>
                <tr style="border-bottom:1px solid #000;">
                  <td>
                    <code>(=) Valor Cobrado </code><br />
                    <span class="mnSt"> </span>
                  </td>
                </tr>
              </table>

              <table class="tbl" style="position:relative">
                <tr style="border:1px solid #000;border-top:0;">
                  <td>
                    <code>Pagador </code> <br />
                    <span class="mnSt">
                      {{ nomedevedor }} <br />
                      CPF/CNPJ: {{ cpf }} <br />
                      {{ endereco }} - {{ bairro }} <br />
                      {{ cidade }} - {{ uf }} - {{ cep }} <br />
                    </span>
                    <code style="position:absolute;bottom:3px;right:20%;"
                      >Código de Baixa:</code
                    >
                  </td>
                </tr>
              </table>

              <table class="tbl">
                <tr>
                  <td>
                    <code style="float:right;"
                      >Autenticação Mecânica/FICHA DE COMPENSAÇÃO
                    </code>
                    <br />
                    <div class="space">
                      <div id="codb"></div>
                    </div>
                  </td>
                </tr>
              </table>

              <hr />
              <br />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </v-container>
</template>

<script>
import { api } from "@/conf/api";
import barcode from "../../conf/barcode";
//import utilidade from "../../conf/utilitario";

export default {
  name: "Nalim",
  data() {
    return {
      datadoc: "",

      vencimento: "",
      valor: "",
      nomedevedor: "",
      barcodeVal: "",
      barcodeVal2: "",

      numdoc: "",
      nossonumero: "",
      instru1: "",
      acordo: "",
      cpf: "",
      endereco: "",
      bairro: "",
      cidade: "",
      uf: "",
      cep: "",
    };
  },
  filters: {
    dinheiro: function(value) {
      return parseFloat(value)
        .toFixed(2)
        .replace(".", ",");
    },
    dataF: function(d2) {
      let d = d2.substr(0, 10);
      return d.substr(8, 2) + "/" + d.substr(5, 2) + "/" + d.substr(0, 4);
    },
  },
  async beforeMount() {
    this.$store.dispatch("setMostraBotoes", { imprimir: true, voltar: true });
    this.pegarBoleto();
  },
  methods: {
    async pegarBoleto() {
      try {
        let divida = this.$store.state.divida_atual;
        let cli = divida.cpobs;
        let p = this.$store.state.negoc_atual.ps;

        let corpo = { idcli: cli, acordo: p };

        let idacordo = await api.post(`especial/nalinconfirma`, corpo);

        console.log("acordo", idacordo.data.ConfirmarAcordoResult);

        corpo = {
          idCli: cli,
          acordo: idacordo.data.ConfirmarAcordoResult,
          parcela: 1,
        };

        let boleto = await api.post("especial/nalinboleto", corpo);

        await this.colocaValores(
          boleto.data[0].objResultado.DocumentElement.Boleto
        );

        barcode(window.$);
        window
          .$("#codb")
          .barcode({ code: `${this.barcodeVal2}`, crc: false }, "int25", {
            barWidth: 1,
            barHeight: 50,
            showHRI: false,
          });
      } catch (e) {
        console.log(e);
        //this.$router.push("erroPage");
      }
    },
    async colocaValores(boleto) {
      (this.datadoc = boleto.DATA_PROCESSAMENTO._text),
        (this.vencimento = boleto.VENCIMENTO._text),
        (this.valor = boleto.VALOR_DOCUMENTO._text),
        (this.nomedevedor = boleto.SACADO._text),
        (this.barcodeVal = boleto.LINHA_DIGITAVEL._text),
        (this.barcodeVal2 = boleto.CODIGO_BARRAS_NUMERO._text),
        (this.numdoc = boleto.NUMERO_DOCUMENTO._text),
        (this.nossonumero = boleto.NOSSONUMERO._text),
        (this.instru1 = boleto.QTDE_ACORDO_DE_ATE._text),
        (this.acordo = "Acordo " + boleto.ID_ACORDO._text),
        (this.cpf = boleto.NU_CPFCNPJ._text),
        (this.endereco =
          boleto.ENDERECO._text +
          " " +
          boleto.ENDERECO_NUMERO._text +
          " " +
          boleto.ENDERECO_COMPLEMENTO._text),
        (this.bairro = boleto.ENDERECO_BAIRRO._text),
        (this.cidade = boleto.ENDERECO_CIDADE._text),
        (this.uf = boleto.ENDERECO_UF._text),
        (this.cep = boleto.ENDERECO_CEP._text);
      //console.log(boleto);
      //credor, cgc, cpf, contrato, boletoObj
      //nome, cpf, contrato, cgc, email
      try {
        let divida = this.$store.state.divida_atual;

        let credor = divida.credor2;
        let cpf = divida.cpcpfcgc;
        let contrato = divida.contrato;
        let cgc = divida.cpcgc;
        let boletoObj = boleto;
        let nome = divida.nome;
        let email = this.$store.state.emailCli;

        let valor = this.valor;
        let valor_entrada = valor;
        let valor_parcela = this.$store.state.negoc_atual.valorP.toLocaleString(
          "pt-BR",
          {
            style: "currency",
            currency: "BRL",
          }
        );
        let venc_o = this.$store.state.venc_atual;
        let venc =
          venc_o.substr(8, 2) +
          "/" +
          venc_o.substr(5, 2) +
          "/" +
          venc_o.substr(0, 4);
        let fatura = boleto.ID_ACORDO._text;
        let remessa = this.$store.state.divida_atual.cpdatrem;
        let parcelas_faltam = this.$store.state.parcelas_faltam;
        let codBarras = boleto.CODIGO_BARRAS_NUMERO._text;
        let L_dig = boleto.LINHA_DIGITAVEL._text;

        let dados = {
          credor,
          cgc,
          cpf,
          contrato,
          boletoObj,
          nome,
          email,
          valor,
          valor_entrada,
          valor_parcela,
          venc,
          fatura,
          remessa,
          parcelas_faltam,
          codBarras,
          L_dig,
        };

        await api.post(`logs/gravarboleto`, dados);
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped>
* {
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  color: #000;
  line-height: 90% !important;
}
.nm {
  font-size: 15px;
}
.mn2 {
  font-size: 17px;
}
.box10 {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  float: left;
  width: 45%;
  min-height: 260px;
  margin: 5px;
  border: 1px solid #ccc;
  padding: 12px;
  overflow: hidden;
  -webkit-box-shadow: 4px 4px 5px 0px rgba(45, 88, 137, 0.75);
  -moz-box-shadow: 4px 4px 5px 0px rgba(45, 88, 137, 0.75);
  box-shadow: 4px 4px 5px 0px rgba(45, 88, 137, 0.75);
}
table td {
  padding: 0cm;
  border: none;
}
.vertical {
  height: 0.8cm;
  border-left: 1px solid #000;
  right: 0;
  bottom: 0;
  position: absolute;
}
.tbl {
  width: 100%;
  border: none;
}
.mnSt {
  font-size: 12px;
  font-weight: 100;
  line-height: 120% !important;
}
.mnStMn {
  font-size: 11px;
  font-weight: 100;
  line-height: 120% !important;
}
.tbl tr,
.tbl tr td {
  border: none;
  padding: 2px;
  height: 35px;
  height: 30px;
  line-height: 1;
}
* {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 12px;
}
a img,
img {
  border: none;
}
ol,
ul {
  list-style: none;
  border: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}
a {
  background-color: transparent;
}
a:active,
a:hover {
  outline: 0;
}
b,
strong {
  font-weight: bold;
}
hr {
  border: 0px none;
  height: 1px;
  background-image: -moz-linear-gradient(
    left center,
    transparent,
    rgba(0, 0, 0, 0.75),
    transparent
  );
  margin-top: 4px;
  margin-bottom: 4px;
}
button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button; /* 2 */
  cursor: pointer; /* 3 */
}
button[disabled],
html input[disabled] {
  cursor: default;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
input[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box; /* 2 */
  box-sizing: content-box;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
h1 {
  font-size: 18px;
}
h2 {
  font-size: 16pt;
}
/* --------------------------------
		geral
--------------------------------- */
html,
body {
  height: 100%;
  background: #f9f9f9;
}
i {
  color: red;
  font-weight: normal;
}
.space {
  clear: both;
  padding: 5px;
  width: 17cm;
  margin: 0 auto;
}
.content {
  margin: 0 auto;
  width: 17cm;
  position: relative;
}
.colM {
  width: 40%;
  float: left;
  text-align: center;
}
.colmn {
  width: 38%;
  text-align: center;
  float: left;
}
.col {
  float: right;
  text-align: center;
  width: 20%;
}
.col1 {
  width: 48%;
  float: left;
  text-align: left;
  padding-right: 1%;
}
.col2 {
  width: 48%;
  float: right;
  text-align: right;
  padding-left: 1%;
}
.long {
  min-height: 7.5cm;
}
.medium {
  min-height: 3cm;
}
.footer {
  position: relative;
  margin-top: 40px;
}
hr {
  border: 0;
  background-color: #fff;
  border-top: 1px dashed #000;
}
ul {
  margin: 0;
  padding: 0;
}
ul li {
  list-style-type: none;
}
code {
  color: #000;
  float: none;
  background: #fff none repeat scroll 0% 0%;
  font-weight: normal;
  font-size: 10px;
}
a {
  color: #2b75bf;
}
a:hover {
  color: #284a6b;
  text-decoration: none;
}
a:focus {
  color: #284a6b;
  text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 5px;
}
/* --------------------------------
		Linhas tabelas e html
--------------------------------- */
table {
  font-weight: 700;
  width: 17cm;
  margin: 0 auto;
  border-collapse: collapse; /* CSS2 */
  background: #fff;
}
table b {
  font-weight: 800;
}
table td {
  vertical-align: top;
  min-width: 4cm;
  border: 2px solid black;
  padding: 5px;
  height: 18px;
}
table th {
  border: 12px solid black;
  background: #fff;
  height: 18px;
}
.semBorda td {
  border: none;
}
/* --------------------------------
			formularios
--------------------------------- */
input {
  float: right;
  background: #3498db;
  margin: 5px;
  border-radius: 4px;
  border: 0;
  color: #fff;
  font-size: 16px;
  padding: 8px;
  text-decoration: none;
  cursor: pointer;
}
input:hover {
  background: #1179be;
}
/* ==========================================================================
   Estilos para impressão
   ========================================================================== */
@media print {
  * {
    background: transparent !important;
    color: #000 !important;
    text-shadow: none !important;
    filter: none !important;
    -ms-filter: none !important;
  }
  body {
    font: 12pt Georgia, "Times New Roman", Times, serif;
    color: #000;
  }
  h1 {
    font-size: 17pt;
  }
  h2 {
    font-size: 16pt;
  }
  h3 {
    font-size: 14pt;
  }
  q:after {
    content: " (" attr(cite) ")";
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a:link:after,
  a:visited:after {
    content: " (" attr(href) ") ";
  }
  p a {
    word-wrap: break-word;
  }
  p {
    widows: 3;
  }
  p {
    orphans: 3;
  }
  .page-break {
    page-break-before: always;
  }
  .notprint {
    visibility: hidden;
    display: none;
  }
  .pb {
    filter: -ms-grayscale(100%) !important;
    filter: -moz-grayscale(100%) !important;
    filter: -webkit-grayscale(100%) !important;
    filter: grayscale(100%) !important;
  }
}
.folha {
  page-break-after: always;
  max-height: 27.5cm;
}
</style>
